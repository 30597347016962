<template>
  <div class="content__scroll">
    <a
      class="content__scroll-link"
      :href="anchor"
      @click.prevent="scrollToPrices"
    >
      <slot />
      <div class="content__scroll-arrow"></div>
    </a>
  </div>
</template>

<script>
export default {
  name: 'ScrollTo',
  props: {
    anchor: {
      type: String,
      required: true,
    },
  },
  computed: {
    navbarHeight: function () {
      const el = document.querySelector('.navbar');
      const compStyles = window.getComputedStyle(el);
      const navbarHeight = compStyles.getPropertyValue('height').replace('px', '');

      return Number(navbarHeight);
    }
  },
  methods: {
    scrollToPrices: function (e) {
      const selector = e.target.getAttribute('href');
      const pricesEl = document.querySelector(selector);

      if (pricesEl) {
        const joe = {
          top: pricesEl.offsetTop - this.navbarHeight,
          behavior: 'smooth',
        };
        // eslint-disable-next-line quotes
        window.scrollTo(joe);
      }
    },
  },
  mounted: function () {},
};
</script>
